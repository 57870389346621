// ============================================== Initialise Fields //

// This will initialise all JS enhanced form fields
// Run this function on any newly created objects if needed

var initFields = function ($parent, callback) {

    var $container = $('body');

    if ($parent) {
        if ($parent instanceof jQuery) {
            $container = $parent;
        } else {
            $container = $($parent);
        }
    }

    var elAsyncFileUploads = $container[0].querySelectorAll('.js-async-file-upload'); // NodeList
    if (elAsyncFileUploads && elAsyncFileUploads.length > 0) {
        forEachNode(elAsyncFileUploads, function (i, el) {
            var elAsyncFileUpload = new FileUploadAsync;
            // Initialise uploader
            elAsyncFileUpload.init({
                el: el
            });
        });
    }

    // bootstrap-datepicker
    $container.find('.js-datepicker').each(function () {
        setDatePicker.init($(this));
        console.log('Datepicker');
    });


    // AJAX form submit
    $container.find('.js-ajax-form').submit(function (e) {
        e.preventDefault();
        // Validated forms should have their own submit handler
        if (!$(this).hasClass('js-validate-form')) {
            submitAjaxForm($(this));
        }
    });

    // General form validation
    $container.find('.js-validate-form').each(function () {
        $(this).validate({
            submitHandler: function (form) {
                if ($(form).hasClass('js-ajax-form')) {
                    submitAjaxForm($(form));
                } else {
                    form.submit();
                }
            }
        });
    });

    // Login form validation
    $container.find('.js-login-form').each(function () {
        $(this).validate({
            submitHandler: function (form) {
                // Disable form / button
                var elSubmitBtn = form.querySelector('.js-submit-btn');
                pendingButton.show(elSubmitBtn);
                var elFormDisable = form.querySelector('.js-login-form-disable');
                pendingForm.disable(elFormDisable);
                // Hide any passwords while submitting
                var elShowPwds = form.querySelectorAll('.js-show-pwd');
                if (elShowPwds && elShowPwds.length > 0) {
                    forEachNode(elShowPwds, function (i, el) {
                        setPwdShow.hidePwd(el);
                    });
                }
                //
                form.submit();
            }
        });
    });

    // open a modal form
    $container.find('.js-mpop-form').each(function () {
        setMpForm.init($(this));
    });

    // Set modal close event
    $container.find('.js-mpop-close').each(function () {
        $(this).off().on('click', $(this), function (e) {
            $.magnificPopup.close();
        });
    });

    // Dependant fields
    $container.find('.js-dependant-fields-group').each(function () {
        setDependantfields.init($(this));
    });

    // Dependant blocks
    $container.find('.js-dependant-block-group').each(function () {
        setDependantblocks.init($(this));
    });

    // Show more / truncated block
    var elTruncatedBlocks = $container[0].querySelectorAll('.js-truncated-block'); // NodeList
    if (elTruncatedBlocks && elTruncatedBlocks.length > 0) {
        forEachNode(elTruncatedBlocks, function (i, el) {
            setTruncatedBlock.init(el, '.js-truncated-block');
        });
    }

    // Run Callback function
    if (typeof callback === 'function') {
        callback();
    }

    // Init Quill editor only on visible items (for performance)
    const quillOptions = {
        debug: 'info',
        modules: {
            toolbar: true,
        },
        placeholder: 'Compose an epic...',
        theme: 'snow'
    };


    if ($container.find('.js-wysiwyg').length) {
        new Quill('.js-wysiwyg', quillOptions);
    }

};

// ============================================== //

// DOM READY  //

// ============================================== //

// Vanilla JS DOM Ready
document.addEventListener('DOMContentLoaded', function () {

    // Resize floating header
    var elHeaderSpacer = document.querySelector('.js-float-header-offset');

    if (elHeaderSpacer) {
        var headerWatcher = scrollMonitor.create(elHeaderSpacer);

        headerWatcher.enterViewport(function () {
            document.body.classList.remove('float-header-scrolled');
        });

        headerWatcher.exitViewport(function () {
            document.body.classList.add('float-header-scrolled');
        });
    }

    // Mobile navigation
    var elMobileNav = document.querySelector('.js-mobile-nav');
    if (elMobileNav) {
        //
        setMobileNav.init();
        /*enquire.register('screen and (max-width:45em)', {
            match : function() {
                setMobileNav.init();
            },
            unmatch : function() {
                setMobileNav.destroy();
            },
        });*/
    }

    // Report navigation SHOW / HIDE on tablet / mobile
    /*var elReportNav = document.querySelector('.js-report-nav');
    if(elReportNav){
        // show / hide
        enquire.register('screen and (min-width:45em) and (max-width:58em)', {
            match : function() {
                setReportNavShow.init();
            },
            unmatch : function() {
                setReportNavShow.destroy();
            },
        });
    }*/

    // Report navigation clicks
    var elReportNavClick = document.querySelector('.js-report-nav-click');
    if (elReportNavClick) {
        setReportNav.init(elReportNavClick);
    }

    // Anchor clicks
    var elScrollAnchor = document.querySelector('.js-scroll-anchor');
    if (elScrollAnchor) {
        setScrollAnchor.init('.js-scroll-anchor');
    }

    // Scrolling navigation
    /*var elScrollers = document.querySelectorAll('.js-simple-scroll');
    if(elScrollers && elScrollers.length>0){
        forEachNode(elScrollers, function (i, el) {
            if(Modernizr.mutationobserver){
                new SimpleBar(el);
                // { autoHide: false }
            }
        });
    }*/

    // Dropdowns
    var elDropdown = document.querySelector('.js-dropdown-toggle');
    if (elDropdown) {
        setDropdown.init();
    }

    // Questionnaire Questions
    var elQs = document.querySelector('.js-q-questions');
    if (elQs) {
        setQuestionnaire.init(elQs);
    }

    // Range slider
    var elRangeSliders = document.querySelectorAll('.js-range-slider'); // NodeList
    if (elRangeSliders && elRangeSliders.length > 0) {
        forEachNode(elRangeSliders, function (i, el) {
            var range = new RangeSlider;
            range.init({
                el: el
            });
            //setRangeSlider.init(el);
        });
    }

    // Show more / truncated block
    /*var elTruncatedBlocks = document.querySelectorAll('.js-truncated-block'); // NodeList
    if(elTruncatedBlocks && elTruncatedBlocks.length>0){
        forEachNode(elTruncatedBlocks, function (i, el) {
            setTruncatedBlock.init(el, '.js-truncated-block');
        });
    }*/

    // File upload dropzone
    // var elAsyncFileUploads = document.querySelectorAll('.js-async-file-upload'); // NodeList
    // if(elAsyncFileUploads && elAsyncFileUploads.length>0){
    //     forEachNode(elAsyncFileUploads, function (i, el) {
    //         var elAsyncFileUpload = new FileUploadAsync;
    //         // Initialise uploader
    //         elAsyncFileUpload.init({
    //             el: el
    //         });
    //     });
    // }


    // Google maps
    var elGMaps = document.querySelectorAll('.js-map'); // NodeList
    if (elGMaps && elGMaps.length > 0) {
        forEachNode(elGMaps, function (i, el) {
            initGmap.init(el);
        });
    }

    // Hidden feedback
    var elHiddenFeedback = document.querySelectorAll('.js-report-hidden-feedback'); // NodeList
    if (elHiddenFeedback && elHiddenFeedback.length > 0) {
        forEachNode(elHiddenFeedback, function (i, el) {
            setReportHiddenFeedback.init(el);
        });
    }

    // Manage raters UI
    var elRatersBlock = document.querySelector('.js-manage-raters');
    if (elRatersBlock) {
        setManageRaters.init(elRatersBlock);
    }

    // Habit Tracker UI
    var elHabitBlock = document.querySelector('.js-habit-tracker');
    if (elHabitBlock) {
        setHabitTracker.init(elHabitBlock);
    }

    // Feelings Gauge Activity UI
    var elFGActivityBlock = document.querySelector('.js-fg-activity');
    if (elFGActivityBlock) {
        setFeelingsGaugeActivity.init(elFGActivityBlock);
    }


    // Feelings Gauge Activity Force Modal
    var elFGForceModalBlock = document.querySelector('.js-feelings-gauge-force-modal');
    if (elFGForceModalBlock) {
        setFeelingsGuageForceModal.init(elFGForceModalBlock);
    }


    // Feelings Gauge Activity Sliders UI
    var elFGSlidersBlock = document.querySelector('.js-feelings-gauge-sliders');
    if (elFGSlidersBlock) {
        setFeelingsGaugeSliders.init(elFGSlidersBlock);
    }

    // Growth Climate Activity UI
    var elGCActivityBlock = document.querySelector('.js-gc-activity');
    if (elGCActivityBlock) {
        setFeelingsGaugeActivity.init(elGCActivityBlock);
    }

    // Card Sorter Activity UI
    var elCardSorterActivityBlock = document.querySelectorAll('.js-card-sorter-activity'); // NodeList
    if (elCardSorterActivityBlock && elCardSorterActivityBlock.length > 0) {
        forEachNode(elCardSorterActivityBlock, function (i, el) {
            setCardSorterActivity.init(el);
        });
    }

    // Checklist Exercise UI
    var elChecklistExerciseBlock = document.querySelector('.js-checklist-exercise');
    if (elChecklistExerciseBlock) {
        setChecklistExercise.init(elChecklistExerciseBlock);
    }

    // Mindset Grid UI
    var elMindsetGridActivityBlock = document.querySelector('.js-ms-grid-activity');
    if (elMindsetGridActivityBlock) {
        setMindsetGridActivity.init(elMindsetGridActivityBlock);
    }

    // Projective Exercise Activity UI
    var elPEActivityBlock = document.querySelector('.js-pe-activity');
    if (elPEActivityBlock) {
        setProjectiveExerciseActivity.init(elPEActivityBlock);
    }

    // Projective Exercise Activity UI
    var elButtonValidate = document.querySelector('.js-button-validate');
    if (elButtonValidate) {
        setButtonValidate.init(elButtonValidate);
    }

    // AJAX field update
    // Not testing for existence as fields might be in datatable
    setAjaxUpdate.init('.js-ajax-update');


    // AJAX button update
    // Not testing for existence as fields might be in datatable
    setAjaxButton.init('.js-ajax-btn');


    // AJAX Modal Form
    // Not testing for existence as fields might be in datatable
    setAjaxModalForm.init('.js-ajax-modal-form-btn');

    // AJAX Modal
    // Not testing for existence as fields might be in datatable
    var ajaxModal = new AjaxModal;
    // Initialise ajaxModal
    ajaxModal.init({
        selector: '.js-ajax-modal-btn',
    });


    // Confirm button
    // Not testing for existence as fields might be in datatable
    setConfirmButton.init('.js-confirm-btn');

    // SHOW SOME CHART CONTENT TO DEVELOPER
    var elDevHoverContent = document.querySelector('.js-dev-hover-content');
    if (elDevHoverContent) {
        setDevHoverContent.init();
    }

    // ANIMATE CHARTS WHEN THEY COME INTO VIEW
    var elAnimCharts = document.querySelectorAll('.js-chart-anim');
    if (elAnimCharts && elAnimCharts.length > 0) {
        setChartAnim.init(elAnimCharts);
    }

    // Lazy load images when in viewport
    var elLazyImgs = document.querySelectorAll('.js-lazy-load-img-in-view');
    if (elLazyImgs && elLazyImgs.length > 0) {
        forEachNode(elLazyImgs, function (i, el) {
            setLazyLoadInView.init(el);
        });
    }

    // Show hide the scroll to top
    var elScrollTopBtn = document.querySelector('.js-scroll-to-top');
    if (elScrollTopBtn) {
        var scrollPos = 2000;
        var top = window.pageYOffset || document.documentElement.scrollTop;
        if (top > scrollPos) {
            document.body.classList.add('show-scroll-top');
        }
        var scrollTopWatcher = scrollMonitor.create(scrollPos);
        scrollTopWatcher.enterViewport(function () {
            document.body.classList.add('show-scroll-top');
        });
        scrollTopWatcher.exitViewport(function () {
            if (!scrollTopWatcher.isAboveViewport) {
                document.body.classList.remove('show-scroll-top');
            }
        });

    }

    // SHOW MARKER ON MINDSET GRID
    var elMindsetGrid = document.querySelectorAll('.js-mindset-grid');
    if (elMindsetGrid && elMindsetGrid.length > 0) {
        forEachNode(elMindsetGrid, function (i, el) {
            setMindsetGrid.init(el);
        });
    }

    // Print page // No longer used
    /*var elPrintBtn = document.querySelector('.js-print-page');
    if(elPrintBtn){
        on('click', document, function(e){
            var el = e.target;
            // Closest captures self as well a parents
            if (!el.closest('.js-print-page')) return;
            // Button clicked
            e.preventDefault();
            // print page
            window.print();
        });
    }*/
    //

    // Set close event for all alerts
    var elClose = document.querySelector('.js-alert-close');
    if (elClose) {
        // Initialise pop up button
        var alertCloseBtnClick = function (e) {

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-alert-close')) return;

            // Button clicked
            e.preventDefault();

            // Hide current alert
            formAlert.hide(e.target.closest('.js-alert'));

        }

        // Attach open event
        on('click', document, alertCloseBtnClick);
    }

    // SHOW PASSWORD BUTTON
    var elShowPwd = document.querySelectorAll('.js-show-pwd');
    if (elShowPwd && elShowPwd.length > 0) {
        forEachNode(elShowPwd, function (i, el) {
            setPwdShow.init(el);
        });
    }

    // SHOW SIDEBAR MODAL
    var elSideBarBtn = document.querySelector('.js-side-bar-modal-btn');
    if (elSideBarBtn) {
        var sideBarBtnClick = function (e) {

            // Don't run if right-click or command/control + click
            if (e.button && e.button !== 0) return;

            // Element within link could have been clicked
            // Closest captures self as well a parents
            if (!e.target.closest('.js-side-bar-modal-btn')) return;

            // Button clicked
            e.preventDefault();

            setMpSideBar.open(e.target);

        }
        // Attach open event
        on('click', document, sideBarBtnClick);
    }

    // Set page scroll links
    setPageScroll.init('.js-page-scroll');

    // Custom selects
    /*var elChoices= document.querySelector('.js-choices');
    if(elChoices){
        var choices = new Choices(elChoices, {
            shouldSort: false,
            //placeholder: true,
            //placeholderValue: 'Please select',
            searchEnabled: false,
            removeItemButton: true,
        });
    }

    var elTestBtn = document.querySelector('.js-choices-test');
    if(elTestBtn){
        elTestBtn.addEventListener('click', function (e) {
            e.preventDefault();
            //
            var elChoiceParent = elTestBtn.closest('.select');
            var elChoice = elChoiceParent.querySelector('.js-choices');
            //
            elChoice.options[elChoice.options.length] = new Option('Text 1', 'Value1');
        });
    }*/


    /*
    // INIT DATA TILES
    var elDataTiles = document.querySelector('.js-data-tiles-list');
    if(elDataTiles){
        setDataTiles.init('.js-data-tiles-list');
    }
    */

    // show popup pointing to 'add to home'
    var elHomeScreenPop = document.querySelector('.js-home-screen-popup');
    if (elHomeScreenPop) {
        setHomeScreenPopup.init();
    }

    // Copy to clipboard
    // Not testing for existence as fields might be in datatable
    setClipboardCopy.init('.js-clipboard-copy');

    // Table toggle
    var elTableToggle = document.querySelector('.js-table-toggle-btn');
    var elTableCheckAll = document.querySelector('.js-table-check-all');
    if (elTableToggle || elTableCheckAll) {
        setTableToggle.init();
    }

    // Report Charts
    var elReportChart = document.querySelector('.js-report-chart');
    if (elReportChart) {
        setReportChart.init('.js-report-chart');
    }

});


// $jquery dom ready
$(document).ready(function () {

    // Datatables (once per page)
    $('.js-datatable').each(function () {
        setDataTable.init($(this), false);// target, editable?
    });

    // Chosen select
    $('.js-select').each(function () {
        setSelect.init($(this));
    });

    // Replace svg with pngs
    if (!Modernizr.svg) {
        $('.js-svg-replace[src$=".svg"]').each(function (index, e) {
            e.src = e.src.replace('.svg', '.png');
        });
    }

    // Is there a popup that needs opening on page load
    var $loadModal = $('.js-popup-open-on-init').first();
    if ($loadModal.length) {
        isModal = false;
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($loadModal, 'data-is-modal', true)) {
            isModal = true;
        }
        openMpForm($loadModal, isModal, false);
    }


    // initialise all JS enhanced form fields
    initFields();

    //Print report functionality
    //printReport.init();


});